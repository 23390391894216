.apartmentTableImg{
    width: 56px;
    height: 56px;
}
.apartmentTreeDiv{
    display: flex;
    justify-content: space-between;
}
.apartment .treeDiv{
    width: calc(100% - 93px);
    min-height: 30px;
    max-height: 200px;
    padding: 4px 6px;
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.apartment .treeDiv .lawArticleItem{
    width: fit-content;
    margin: 8px 8px 0 2px;
    background-color: #e5e5e5;
    cursor: pointer;
    display: flex;
    word-break: break-all;
}
.apartment .treeDiv .closeImg{
    position: relative;
    top: -6px;
    right: -6px;
    color: #787878;
}
.apartment #apartmentMap{
    width: 100%;
    height: 400px;
}