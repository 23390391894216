.aptSetup .aptSetupMain{
    position: relative;
    /* font-family: Helvetica Neue, Helvetica, Arial, sans-serif; */
    font-size: 14px;
    line-height: 1.42857;
    color: #333;
    min-height: calc(100vh - 190px);
    margin-top: 10px;
    border-top: 1px solid #dadadd;
    background-color: #f6f6f6;
}
.aptSetupMain .widgets{
    position: absolute;
    top: 0;
    bottom: 47px;
    left: 0;
    overflow: auto;
    background-color: #E6F7FF;
}
.aptSetupMain .widgets ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
.aptSetupMain .widgets .widgetsItems{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    color: #333;
    cursor: default;
}
.aptSetupMain .widgets .widgetsItems::before, .aptSetupMain .widgets .widgetsItems::after {
    position: absolute;
    right: 0;
    left: 0;
    height: 1px;
    content: "";
}
.aptSetupMain .widgets .widgetsItems::before {
    top: 0;
}
.aptSetupMain .widgets .widgetsItems::after {
    bottom: 0;
    background-color: #dadadd;
}
.aptSetupMain .widgets .widgetsItems:hover {
    color: #333;
    background-color: #ffffff;
}
.aptSetupMain .widgetsItems:hover i {
    color: #1f273b;
}
.aptSetupMain .widgetsItems:hover i:before {
    color: #1f273b;
}
.aptSetupMain .widgetsItems i {
    line-height: 1.5;
    color: #333;
    text-align: center;
}
.aptSetupMain .widgetsItems i:before {
    display: inline-block;
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: #333;
}
/* 控制 */
.aptSetupMain .control{
    position: absolute;
    top: 50%;
    left: 18%;
    width: 24px;
    min-height: 24px;
    padding: 2px;
    -webkit-box-shadow: 0 0 0 2px #ececec;
    box-shadow: 0 0 0 2px #ececec;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.aptSetupMain .dd {
    list-style: none;
}
.aptSetupMain .dd .ddList {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;
}
.aptSetupMain .ddItem, .ddEmpty, .ddPlaceholder {
    position: relative;
    display: block;
    min-height: 20px;
}
.aptSetupMain .decorateControlItems {
    display: block;
    height: 20px;
    margin-bottom: 2px;
    background-color: #ececec;
}
.aptSetupMain .decorateControlItems.decorateActive div {
    cursor: move;
    background-color: #fc8a17;
}
.aptSetupMain .decorateControlItems:last-child {
    margin-bottom: 0;
}
.aptSetupMain .ddHandle {
    display: block;
    height: 20px;
}

.aptSetupMain .layout{
    position: absolute;
    top: 10px;
    bottom: 57px;
    left: 24%;
    width: 450px;
    overflow: hidden;
}
.aptSetupMain .layout .layoutHeader{
    position: absolute;
    top: 0;
    left: 3px;
    z-index: 99;
    width: 320px;
    height: 64px;
    background-image: url(../../public/img/titlebar.png);
    background-repeat: no-repeat;
}
.aptSetupMain .layout .layoutBobdy{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 500px;
    overflow-y: auto;
}
.aptSetupMain .layout .layoutBobdy .layoutView{
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 320px;
    min-height: 100%;
    padding-top: 64px;
    margin-left: 3px;
    background-color: #efeff4;
}
.aptSetupMain .layout .layoutBobdy .layoutView .shopSignEdit{
    position: absolute;
    top: 64px;
    left: 100%;
    z-index: 999;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-top: 1px dashed #cccccc;
}
.aptSetupMain .layoutView .shopSign{
    position: relative;
    width: 100%;
    height: 80px;
    overflow: hidden;
}
.aptSetupMain .layoutView .shopSign .shopSignCover{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    background: rgba(0, 0, 0, 0.3);
}
/* .aptSetupMain .layoutView .shopSign .shopSignImg{
    margin: -1rem;
} */
.aptSetupMain .layoutView .shopSign .shopSignImg img{
    width: 100%;
    -webkit-filter: blur(0.2rem);
            filter: blur(0.2rem);
}
.aptSetupMain .layoutView .shopSign .shopSignInfo{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding: 8px;
}
.aptSetupMain .layoutView .shopSign .shopSignInfo .shopLogo{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 20%;
    background: #ffffff;
}
.aptSetupMain .layoutView .shopSign .shopSignInfo .shopLogo::after {
    display: block;
    padding-top: 100%;
    content: "";
}
.aptSetupMain .layoutView .shopSign .shopSignInfo .shopLogo img {
    display: block;
    width: 100%;
}
.aptSetupMain .layoutView .shopSign .shopSignInfo .shopName{
    -webkit-box-flex: 1;
    -ms-flex: 1;
        flex: 1;
    padding-left: 8px;
    color: #fff;
}
.aptSetupMain .layoutView .shopSign .shopSignInfo .shopName i{
    display: inline-block;
    font-size: 12px;
    color: #fff;
    vertical-align: middle;
}
.aptSetupMain .layoutView .shopSign .shopSignInfo .shopName a{
    display: inline-block;
    max-width: 85%;
    overflow: hidden;
    font-size: 12px;
    color: #fff;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
}
.aptSetupMain .layoutView .shopSign .shopSignInfo .shopFavoriteBtn{
    position: relative;
    display: inline-block;
    padding: 0 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #333;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    -webkit-transition: all;
    transition: all;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;
    -webkit-transition-duration: 0.2s;
            transition-duration: 0.2s;
}
/* 区域块 start*/
.aptSetupMain .decorateLayoutItems {
    min-height: 30px;
    margin-bottom: 10px;
    /* background: #ffffff; */
}

.aptSetupMain .decorateLayoutItems.decorateActive {
    position: relative;
    -webkit-box-shadow: 0 0 3px #dd4b39;
            box-shadow: 0 0 3px #dd4b39;
}

.aptSetupMain .decorateLayoutItems.decorateActive .decorate-action-remove {
    position: absolute;
    top: 0;
    left: 100%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    background: #dd4b39;
}
.aptSetupMain .decorateLayoutItems.decorateActive .decorate-action-remove i:before {
    font-size: 16px;
}
/* 区域块 end */

/* 商品展示 */
.aptSetupMain .sectionTitle {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aptSetupMain .sectionTitle .titleTxt {
    font-size: 16px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.aptSetupMain .sectionTitle .titleBtn {
    font-size: 12px;
    color: #00f;
    white-space: nowrap;
}

/* 商品展示 */
.aptSetupMain .proListGrid {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    gap: 10px
}
.aptSetupMain .proListGrid .productItem{
    width: calc(50% - 10px);
    background: #fff;
    border-radius: 4px;
}
.aptSetupMain .proListGrid .productItem .productImg{
    width: 100%;		
    height: 145px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.aptSetupMain .proListGrid .productItem .goodsTitle{
    flex: 1;
    margin: 5px 5px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
}
.aptSetupMain .proListGrid .productItem .goodsHouseTag{
    margin: 5px 5px 0;
    height: 18px;
    overflow: hidden;
}
.aptSetupMain .proListGrid .productItem .goodsHouseTag .descBox{
    float: left;
    margin-right: 5px;
    padding: 3px 5px;
    /* height: 12px; */
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    background-color: #2e35bd;
    opacity: 0.5;
    border-radius: 4px;
}
.aptSetupMain .proListGrid .productItem .productPrice{
    margin: 5px 5px 0;
    line-height: 13px;
    font-size: 13px;
    font-weight: bold;
    color: #fd3959;
}
.aptSetupMain .proListGrid .productItem .productAddress{
    font-size: 12px;
    line-height: 12px;
    color: #333;
    margin: 5px;
}
/* 商品展示 */

.aptSetupParam{
    overflow: hidden;
    font-size: 12px;
    line-height: 1.3;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 3px;
}

.aptSetupNot{
    color: #0faeaa;
}

.aptSetupIng{
    color: #eb0045;
}

.aptSetupEnd{
    color: #888b92;
}

.aptSetupBottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.aptSetupPriceRow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.aptSetupPremise{
    font-size: 10px;
}

.aptSetupPrice{
    font-size: 18px;
    font-weight: 500;
}

.aptSetupTips{
    color: #b1b2b6;
    font-size: 11px;
    margin-top: 2px;
    white-space: nowrap;
}
/* 商品展示end */

/* 轮播图 */
.aptSetupMain .shopexSlider{
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
}
/* .aptSetupMain .shopexSlider .shopex-segmented-control.shopex-segmented-control-inverted .shopex-control-item.shopexActive {
    border-bottom: 0;
}

.aptSetupMain .shopexSlider .shopex-segmented-control.shopex-segmented-control-inverted ~ .shopexSliderGroup .shopexSliderItem {
    border-top: 1px solid #c8c7cc;
    border-bottom: 1px solid #c8c7cc;
} */

.aptSetupMain .shopexSlider .shopexSliderGroup {
    position: relative;
    font-size: 0;
    white-space: nowrap;
    -webkit-transition: all 0s linear;
    transition: all 0s linear;
}

.aptSetupMain .shopexSlider .shopexSliderGroup .shopexSliderItem {
    position: relative;
    display: inline-block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    font-size: 14px;
    white-space: normal;
    vertical-align: middle;
}

.aptSetupMain .shopexSlider .shopexSliderGroup .shopexSliderItem > a:not(.shopex-control-item) {
    position: relative;
    display: block;
    line-height: 0;
}

.aptSetupMain .shopexSlider .shopexSliderGroup .shopexSliderItem img {
    width: 100%;
}

.aptSetupMain .shopexSlider .shopexSliderGroup.shopexSliderLoop {
    -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
}
.aptSetupMain .shopexSlider .shopexSliderStyle{
    transform: translate3d(0px, 0px, 0px) translateZ(0px) !important;
    transition-duration: 0ms;
}

.aptSetupMain .shopexSliderIndicator {
    display: flex;
    justify-content: center;
    gap: 3px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    /* text-align: center; */
    /* background: none; */
}

.aptSetupMain .shopexSliderIndicator .shopexIndicator {
    display: inline-block;
    width: 6px;
    height: 6px;
    cursor: pointer;
    background: #ffffff;
    border-radius: 50%;
}

.aptSetupMain .shopexSliderIndicator .shopexActive.shopexIndicator {
    background: #ea6928;
}
/* 轮播图end */

/* 单图展示 */
.aptSetupMain .imgBlock img {
    display: block;
    width: 100%;
    height: auto;
}
/* 单图展示end */

/* 文字展示 */
.aptSetupMain .shopNav {
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 12px 0;
    background: #ffffff;
    border-bottom: 1px solid #e4e5e6; */
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 0 10px;
}

.aptSetupMain .shopNav .shopNavItem {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    text-align: center;
    padding: 10px 0;
    font-size: 14px;
    line-height: 14px;
    background: #ffffff;
    border-radius: 5px;
}
.aptSetupMain .shopNav .shopNavItem a{
    color: #00f !important;
}
/* 文字展示end */

/* 图片导航 */
.aptSetupMain .shopPicNav{
    width: 100%;
}
.aptSetupMain .shopPicNavItem{
    padding: 0 10px;
    background: #f6f6f6;
}
.aptSetupMain .shopPicNavItem img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
/* 图片导航end */

/* 优惠券 */
.aptSetupMain .sectionContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 10px 5px;
}

.aptSetupMain .couponList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0;
}

.aptSetupMain .couponList.couponsInline .couponItem {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.aptSetupMain .couponItem {
    position: relative;
    width: 30%;
    padding-top: 9.5px;
    padding-bottom: 6.5px;
    margin: 0 4px;
    text-align: center;
    border: 1px dashed #ea2329;
}

.aptSetupMain .couponItem .couponDenomination {
    margin-right: 8px;
    margin-bottom: 3px;
    margin-left: 8px;
    overflow: hidden;
    font-size: 16px;
    font-weight: lighter;
    line-height: 1;
    color: #ea2329;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.aptSetupMain .couponItem .couponRule {
    margin-right: 8px;
    margin-left: 8px;
    overflow: hidden;
    font-size: 11px;
    font-weight: lighter;
    color: #666666;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.aptSetupMain .couponItem::after {
    position: absolute;
    top: 2px;
    left: 1px;
    font-size: 10px;
    font-weight: lighter;
    line-height: 1;
    color: #ffffff;
    content: "券";
}

.aptSetupMain .couponItem::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    border-color: transparent;
    border-style: solid;
    border-width: 24px;
    border-top-color: #ea2329;
    border-left-width: 0;
}
/* 优惠券end */


/* 右边操作栏 */
.aptSetupMain .rightForm {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 47px;
    width: 400px;
    padding: 10px;
    overflow: auto;
    background-color: #efefef;
}
.decorate-form-tip {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    margin-top: -10px;
    color: #d1d1d1;
    text-align: center;
    text-shadow: 1px 1px 0 #ffffff;
}
.aptSetupMain .rightForm h5 {
    margin-bottom: 10px;
    font-size: 14px;
}
.rightForm .form-group {
    margin-bottom: 15px;
}

/* 商品列表 start*/
.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}
.selected_goods .goodsList {
    display: flex;
    gap: 5px;
    font-size: 12px;
    color: #666;
}
.selected_goods .goodsList .productImg img {
    width: 74px;
    height: 74px;
    border: 1px solid #ccc;
}
.selected_goods .goodsList .productInfo {
    width: calc(100% - 128px);
    height: 74px;
    line-height: 19px;
}
.productInfo .productTitle {
    /* height: 15px; */
    font-size: 14px;
    line-height: 16px;
    color: #333;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.productInfo .productHouseTag{
    margin-top: 5px;
    height: 18px;
    overflow: hidden;
}
.productInfo .productHouseTag .descBox{
    float: left;
    margin-right: 5px;
    padding: 3px 5px;
    font-size: 12px;
    line-height: 12px;
    color: #fff;
    background-color: #2e35bd;
    opacity: 0.5;
    border-radius: 4px;
}
.productInfo .salesPriceBox{
    margin-top: 5px;
    line-height: 13px;
    font-size: 13px;
    font-weight: bold;
    color: #fd3959;
}
.productInfo .productAddress {
    margin-top: 5px;
    font-size: 12px;
    line-height: 12px;
    color: #333;
}
.selected_goods .productRemove {
    width: 40px;
    padding-top: 15px;
    color: #ffffff;
    text-align: center;
    background: #dd4b39;
}
.selected_goods .sort{
    width: 40px;
    padding-top: 15px;
    color: #ffffff;
    text-align: center;
    background-color: #eebd79;
}

.product .selected_goods i:before {
    font-size: 14px;
}


/* 商品列表 end */

/* 文本导航 start*/
.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav .text-muted {
    color: #777;
}
.nav .form-item {
    position: relative;
    padding-right: 34px;
}
.nav .ant-input{
    height: 40px;
}
.navLinkName {
    width: calc(100% - 81px);
}
.nav .btn-group-justified {
    display: flex;
    gap: 1px;
}
.nav .shop-nav-btn {
    height: 35px;
}

/* 文本导航 end*/

/* 轮播图 start */
.slider .slider-form-item {
    position: relative;
    padding-right: 34px;
}
.aptSetupMain .form-item-action {
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    text-align: center;
    cursor: pointer;
  }
.slider .ant-input{
    height: 40px;
}
.slider .imgsrc {
    width: calc(100% - 81px);
    border: 1px solid #ccc !important;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.aptSetupMain .input-group-addon {
    padding: 11px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #333333;
    text-align: center;
    background-color: #eeeeee;
    border: 1px solid #cccccc;
    border-radius: 5px;
}
.aptSetupMain .input-group-addon:last-child {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/* 轮播图 end */

/* 优惠券 start */

.coupon .help-block{
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}
.shop-coupon-list {
    padding: 12px;
    background: #efefef;
    border-radius: 5px;
}

.shop-coupon-list.form-list {
    padding: 0;
}

.shop-coupon-list li {
    position: relative;
    padding: 6px;
    margin-bottom: 10px;
    background: #ffffff;
}

.shop-coupon-list li:last-child {
    margin: 0;
}

.shop-coupon-list li.active {
    -webkit-box-shadow: 0 0 5px #ff0000;
            box-shadow: 0 0 5px #ff0000;
}

.shop-coupon-list .coupon-amount {
    font-size: 24px;
    color: #ff0000;
}

.shop-coupon-list .coupon-rule {
    color: #666666;
}

.shop-coupon-list .coupon-deadline {
    font-size: 11px;
    color: #dadadd;
}

.shop-coupon-list .coupon-del {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #cccccc;
    cursor: pointer;
}
.shop-coupon-btn{
    width: 100%;
    height: 40px;
}
/* 优惠券 end */

/* 新闻 */
.aptSetup .newsRow{
    position: relative;
    height: 34px;
    line-height: 34px;
}
.aptSetup .newsTitle{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 24px);
}
.aptSetup .shopNews{
    padding: 0 10px 10px;
    line-height: 30px;
    color: #111;
}
.aptSetup .shopNewsItem{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #122e67;
}
.aptSetup .newsRow .news-fa-caret-up{
    position: absolute;
    top: 0;
    right: 68px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    text-align: center;
    cursor: pointer;
}
.aptSetup .newsRow .news-fa-caret-down{
    position: absolute;
    top: 0;
    right: 34px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    color: #666666;
    text-align: center;
    cursor: pointer;
}
/* 新闻 end */


/* 保存按钮 */
.aptSetupMain .footbar{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 14px 10px 0;
    text-align: right;
    background: #ffffff;
    border-top: 1px solid #dadadd;
}