.wholeRentHouseSouce .treeDiv{
    width: calc(100% - 93px);
    min-height: 30px;
    max-height: 200px;
    padding: 4px 6px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.wholeRentHouseSouce{
    position: relative;
    border-radius: 10px;
    border: 2px solid #ccd6ff;
    padding: 15px 10px 10px;
    margin: 20px 0;
    width: 1228px;
}
.wholeRentHouseSouce2{
    position: relative;
    border-radius: 10px;
    border: 2px solid #ccd6ff;
    padding: 15px 10px 10px;
    margin: 20px 0;
    width: 1228px;
    display: inline-block;
    margin-right: 47px;
}
.wholeRentHouseSouce1{
    position: relative;
    border-radius: 10px;
    border: 2px solid #ccd6ff;
    padding: 15px 10px 10px;
    margin: 20px 0;
    width: 1428px;
}
.buttonStyles{
    margin-left: 47px;
    margin-bottom: 10px;
}
.inputButton{
    margin-bottom: 10px;
}
.sysSelectDuan{
    width: 410px;
    padding-left: 20px;
}
.sysMinInput{
    width: 80px;
    margin-left: 20px;
    margin-right: 10px;
}
.buttonYellowxuanzeStyle {
    background: #eebd79;
    border-color: #eebd79;
    margin-left: 15px;
}
.sysColDuan {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.textarea{
    width: 1000px;
}
.sysShortInputNumber{
    width: 100% !important;
}
.blueBoxTitle1 {
    margin-top: 15px;
    position: absolute;
    top: -25px;
    left: 30px;
    background-color: #fff;
    width: 15%;
    text-align: center;
}
.blueBoxTitle3 {
    margin-top: 15px;
    position: absolute;
    top: -25px;
    left: 30px;
    background-color: #fff;
    width: 28%;
    text-align: center;
}
.imgSrc{
    width: 80px;
    height: 80px;
}
.blueBoxTitle2 {
    margin-top: 15px;
    position: absolute;
    top: -25px;
    left: 30px;
    background-color: #fff;
    width: 15%;
    text-align: center;
}
.wholeRentHouseSouce .ant-checkbox-disabled + span {
    color: black !important;
    cursor: not-allowed;
}
.wholeRentHouseSouce .retentioBlueBox1 {
    position: relative;
    border-radius: 10px;
    border: 2px solid #ccd6ff;
    padding: 15px 10px 10px;
    margin-bottom: 25px;
    margin-top: 30px;
}
.wholeRentHouseSouce .treeDiv .lawArticleItem{
    width: fit-content;
    margin: 8px 8px 0 2px;
    background-color: #e5e5e5;
    cursor: pointer;
    display: flex;
    word-break: break-all;
}
.wholeRentHouseSouce .treeDiv .closeImg{
    position: relative;
    top: -6px;
    right: -6px;
    color: #787878;
}
.wholeRentHouseSouce #apartmentMap{
    width: 100%;
    height: 400px;
}

.wholeRentHouseSouce2 .flexBoxOne{
    display: flex;
    align-items: center;
    margin: 20px;
}
.wholeRentHouseSouce2 .headImg{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 20px;
}
.wholeRentHouseSouce2 .headImgTitle{
    margin: 0 20px;
}
.wholeRentHouseSouce2 .rightBox{
    margin-left: 20px;
}
.wholeRentHouseSouce2  .labelTitle{
    font-size: 18px;
    font-weight: 600;
}
.wholeRentHouseSouce2 .label{
    margin-right: 5px;
    color: #707070;
}
.wholeRentHouseSouce2 .propertyImg{
    width: 100px;
    height: 100px;
}
.communityTabs{
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: 24px;
}

.communityTabs .tabsItem{
    width: 50%;
    text-align: center;
    color: #000;
    font-weight: 700;
    font-size: 14px;
}


.communityTabs .selectTabsItem{
    color: #eebd79;
    width: 50%;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}
.storeInput{
    display: inline-block !important;
    width: 290px !important;
    margin-left: 8px;
}
   
.storeTypeInput{
    width: 100px !important;
    display: inline-block !important;
}
.storeWidth{
    width: 400px !important;
}
.widthHouseName2{
    width: 80px !important;
}

