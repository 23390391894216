.messageShowStyle{
    /* margin-top: 10px; */
    white-space: pre-wrap;
    /* position: absolute;
    width: 254px; */
}
.messageShowStyle .messageAlert{
    margin-bottom: 15px;
    margin-top: 15px;
}

.messageShowStyle .success {
    color: #3c763d;
}

.ant-alert-success {
    background-color: #dff0d8 !important;
    border-color: #d6e9c6 !important;
}

.messageShowStyle .success .ant-alert-message {
    color: #3c763d;
}

.messageShowStyle .success .ant-alert-description {
    color: #3c763d;
}

.messageShowStyle .error {
    color: #A94442;
}

.messageShowStyle .error .ant-alert-message {
    color: #A94442;
}

.messageShowStyle .error .ant-alert-description {
    color: #A94442;
}


.messageShowStyle .warning {
    color:#faad14;
}

.messageShowStyle .warning .ant-alert-message {
    color:#faad14;
}

.messageShowStyle .warning .ant-alert-description {
    color:#faad14;
}

.messageShowStyle .info {
    color:#40a9ff;
}

.messageShowStyle .info .ant-alert-message {
    color:#40a9ff;
}

.messageShowStyle .info .ant-alert-description {
    color:#40a9ff;
}



.loginMessageShowStyle{
    /* margin-top: 10px; */
    white-space: pre-wrap;
    position: absolute;
    width: 254px;
    top: 15px;
}

.loginMessageShowStyle .success {
    color: #3c763d;
}

.loginMessageShowStyle .success .ant-alert-message {
    color: #3c763d;
}

.loginMessageShowStyle .success .ant-alert-description {
    color: #3c763d;
}

.loginMessageShowStyle .error {
    color: #A94442;
}

.loginMessageShowStyle .error .ant-alert-message {
    color: #A94442;
}

.loginMessageShowStyle .error .ant-alert-description {
    color: #A94442;
}


.loginMessageShowStyle .warning {
    color:#faad14;
}

.loginMessageShowStyle .warning .ant-alert-message {
    color:#faad14;
}

.loginMessageShowStyle .warning .ant-alert-description {
    color:#faad14;
}

.loginMessageShowStyle .info {
    color:#40a9ff;
}

.loginMessageShowStyle .info .ant-alert-message {
    color:#40a9ff;
}

.loginMessageShowStyle .info .ant-alert-description {
    color:#40a9ff;
}

