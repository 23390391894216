.default{
  width: 100%;
  margin-left: 15px;
  max-width: 1200px;
}
.default .defaultSelect{
  margin: 20px 10px;
}
.default .defaultSelectLeft{
  width: 70px;
}
.default .defaultSelectRight{
  width: calc(100% - 70px);
}
.default .marginRow{
  margin-bottom: 10px;
}
.default .tableItem{
  width: 100%;
  text-align: justify;
}
.default .tableItem .defaultTitle{
  font-size: 18px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
}
.default .tableItem .defaultTitle .downloadContract{
  width: 100px;
  height: 30px;
  line-height: 30px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #fff;
  background-color: #337AB7;
  border-radius: 15px;
  cursor: pointer;  /*鼠标箭头变手指*/
  user-select: none;
}

.default .tableItem .defaultContext{
  padding: 20px;
  border: 1px solid #ccc;
  margin-top: 10px;    
}