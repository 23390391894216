.proBrandEstateImg{
    width: 56px;
    height: 56px;
}
.proBrandEstateTreeDiv{
    display: flex;
    justify-content: space-between;
}
.proBrandEstate .treeDiv{
    width: calc(100% - 93px);
    min-height: 30px;
    max-height: 200px;
    padding: 4px 6px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.proBrandEstate .treeDiv .lawArticleItem{
    width: fit-content;
    margin: 8px 8px 0 2px;
    background-color: #e5e5e5;
    cursor: pointer;
    display: flex;
    word-break: break-all;
}
.APPROVE{
    color: #70ad47;
}
.REJECT{
    color: #c00000;
}
.PEND_ONSHELF{
    color: #7f7f7f;
}
.SUBMIT{
    color: #ffc000;
}
.auditRemark{
    background: #e92723;
    padding: 6px 10px;
    border: 1px solid #c00000;
    color: #eee;
    margin-bottom: 10px;
    max-width: 1226px;
}
.proBrandEstateOperation{
    justify-content: flex-end !important;
}
.proBrandEstateOperation div{
    margin-left: 5%;
}