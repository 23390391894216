/**当前项目公共样式zh开头**/

body {
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**禁止样式*/

.button[disabled], html input[disabled] {
  cursor: not-allowed;
}

.comsRoot {
  width: auto;
  height: auto;
  margin-top: 10px;
  margin-left: 30px;
}

.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent  !important;
  border-color: transparent  !important;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  width: 150px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #eebd79 !important;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}

.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-block;
  font-size: 0;
}

.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: none !important;
  background-image: none;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: none !important;
  border-radius: 2px;
}

/* 列表头部样式 */

.comsRoot .ant-table-thead>tr>th {
  /* padding: 8px 16px!important; */
  white-space: normal;
  word-break: break-all;
}

/* 列表内容样式 */

.comsRoot .ant-table-tbody {
  border-left: 1px dashed #cecece;
}

.comsRoot .ant-table-tbody>tr>td {
  padding: 8px 10px !important;
  white-space: normal;
  word-break: break-all;
}

/*表格选中样式*/

.ant-table-tbody>tr.ant-table-row-selected td {
  background-color: #99ccff !important;
}

/* 分页居中样式 */

.comsRoot .ant-tabs-bar {
  margin: 0 0 0px 0 !important;
}

/* 表格行背景颜色 */

/* 奇数行样式 */

.comsRoot tr:nth-child(odd) {
  background: #fff;
}

/* 偶数行样式 */

.comsRoot tr:nth-child(even) {
  background: #f3f6ff;
}

/* 偶数行样式 */
.textAreaOrange {
  color: #ff851b;
}

/* table样式 */
.ant-table-thead > tr > th{ /* 表头行样式 */
  color: #000;
  background-color: #f2f3f5!important;
  font-weight: 700;
}
.tabWidth {
  width: 66.5%;
}

.tableRightAlignment {
  text-align: right !important;
}

.tableCenterAlignment {
  text-align: center !important;
}

.tableWidth3 {
  width: 3%;
}

.tableWidth302 {
  width: 3.2%;
}

.tableWidth305 {
  width: 3.5%;
}

.tableWidth4 {
  width: 4%;
}

.tableWidth405 {
  width: 4.5%;
}

.tableWidth5 {
  width: 5%;
}

.tableWidth505 {
  width: 5.5%;
}

.tableWidth5088 {
  width: 6.1%;
}

.tableWidth6 {
  width: 6%;
}

.tableWidth601 {
  width: 6.1%;
}

.tableWidth605 {
  width: 6.5%;
}

.tableWidth7 {
  width: 7%;
}

.tableWidth705 {
  width: 7.5%;
}

.tableWidth8 {
  width: 8%;
}

.tableWidth8033 {
  width: 8.7%;
}

.tableWidth805 {
  width: 8.5%;
}

.tableWidth9 {
  width: 9%;
}

.tableWidth10 {
  width: 10%;
}

.tableWidth11 {
  width: 11%;
}

.tableWidth12 {
  width: 12%;
}

.tableWidth13 {
  width: 13%;
}

.tableWidth14 {
  width: 14%;
}

.tableWidth15 {
  width: 15%;
}

.tableWidth16 {
  width: 16%;
}

.tableWidth17 {
  width: 17%;
}

.tableWidth1708{
  width: 17.8%;
}

.tableWidth18 {
  width: 18%;
}

.tableWidth19 {
  width: 19%;
}

.tableWidth1906{
  width: 19.6%;
}

.tableWidth20 {
  width: 20%;
}

.tableWidth205 {
  width: 20.5%;
}

.tableWidth23 {
  width: 23%;
}

.tableWidth24 {
  width: 24%;
}

.tableWidth25 {
  width: 25%;
}

.tableWidth30 {
  width: 30%;
}

.tableWidth31 {
  width: 31%;
}

.tableWidth33 {
  width: 33%;
}

.tableWidth34 {
  width: 34%;
}

.tableWidth35 {
  width: 35%;
}

.tableWidth40 {
  width: 40%;
}

.tableWidth44 {
  width: 44%;
}

.tableWidth50 {
  width: 50%;
}

.tableWidth52 {
  width: 52%;
}

.tableWidth55 {
  width: 55%;
}

.tableWidth555 {
  width: 55.5%;
}

.tableWidth56 {
  width: 56%;
}

.tableWidth60 {
  width: 60%;
}

.tableWidth65 {
  width: 65%;
}

.tableWidth70 {
  width: 60%;
}

.tableWidth72 {
  width: 72%;
}

.tableWidth85 {
  width: 85%;
}

.pd_100percent {
  width: 100%;
}

.parts-numberInput {
  width: 100% !important;
}

.discoloration:hover {
  text-decoration: underline;
  color: #c18b7f;
}

.ant-popover-inner-content{
  width: 850px;
}

/* 超出长度显示省略号 */

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 超出2行显示省略号 */
.twoRowEllipsis {
  font-size: 14px;
  line-height: 32px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  width: 100%;
}

/* 假from表单框报错样式 */

.receiveBorder {
  border: 1px solid #f5222d !important;
}

.receiveBorder:hover {
  border: 1px solid #f5222d !important;
}

.receiveBorder:focus {
  border: 1px solid #f5222d !important;
}

.falseFromError {
  color: #f5222d;
  font-size: 13px;
  height: auto;
  float: left;
}

/* input占位符颜色 */

input:-ms-input-placeholder {
  color: #cecbcb !important;
  opacity: 1;
}

input::-webkit-input-placeholder {
  color: #cecbcb !important;
  opacity: 1;
}

/* textarea占位符颜色 */

textarea:-ms-input-placeholder {
  color: #cecbcb !important;
  opacity: 1;
}

textarea::-webkit-input-placeholder {
  color: #cecbcb !important;
  opacity: 1;
}

.ant-picker.ant-picker-disabled {
  background: none!important;
  border-color: none!important;
  cursor: not-allowed;
  border: none;
}
.redSize{
  color: #f5222d;
}


/* input边框颜色 start*/

.has-error .form-control {
  border-color: #f5222d;
}

.has-error .form-control:hover {
  border-color: #f5222d;
}
.collectImg{
  margin-left: 30px;
}

.has-error .form-control:focus {
  border-color: #f5222d;
}

.has-error .form-control:active {
  border-color: #f5222d;
}

.has-success .form-control {
  border-color: #ccc;
}

.has-success .form-control:hover {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
}

.has-success .form-control:focus {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
}

.has-success .form-control:active {
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-color: #40a9ff;
}

/* input边框颜色 end*/

/* 全局Button背景色 */

.btn-primary {
  background-color: #333 !important;
  border-color: #333 !important;
}

.btn-primary:hover {
  background-color: #333;
  border-color: #333;
}

.btn-primary:focus {
  background-color: #333;
  border-color: #333;
}

.btn-primary:active {
  background-color: #333;
  border-color: #333;
}

/* 按钮样式 start*/

.buttonBlackStyle {
  background-color: #333 !important;
  border-color: #333 !important;
}

.buttonWhiteStyle {
  background-color: #fff !important;
  border-color: #ccc !important;
  color: #333 !important;
}

.buttonBlueStyle {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
}

.buttonGreenStyle {
  background-color: #5cb85c !important;
  border-color: #5cb85c !important;
}

.buttonSkyBlueStyle {
  background-color: #5bc0de !important;
  border-color: #5bc0de !important;
}

.buttonYellowStyle {
  background-color: #f0ad4e !important;
  border-color: #f0ad4e !important;
}

.buttonYellowTwoStyle {
  background: #eebd79;
  border-color: #eebd79;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #eebd79;
  border-color: #eebd79;
}

.flexBox{
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  font-size: 14px;
}

img[src=""],img:not([src]){
  opacity:0;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #eebd79;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}

.buttonRedStyle {
  background-color: #d9534f !important;
  border-color: #d9534f !important;
}

/* 按钮样式 end*/

/*表格选中样式*/

.ant-table-tbody>tr.ant-table-row-selected td {
  background-color: #99ccff !important;
}

.ant-table-thead>tr>th, .ant-table-tbody>tr>td {
  padding: 8px 16px;
  white-space: normal;
  word-break: break-all;
}

.ant-table-column-has-sorters {
  padding: 8px 16px !important;
}

.ant-table-selection-column {
  width: 0%;
  padding: 8px 16px !important;
}

.ant-table-column-sorters {
  padding: 0px 0px !important;
}

/**右边内容区域--选项卡下划线*/

.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover {
  color: #333;
  font-weight: 700;
  border: 0;
  border-bottom: 2px solid #8c9ed3;
}

/* ------------------------------------ 项目内容样式 ------------------------------------ */
/* ------------------------------------ 中间内容区域 ------------------------------------ */

#main {
  display: flex;
}

/* ----------------- 中间左边菜单栏 ----------------- */

#main #leftMenu {
  height: auto;
  min-height: 100vh;
  border-right: 1px solid #ebebeb;
  transition: all ease 0.5s;
  background: #eebd79;
  padding: 0 5px;
  z-index: 111;
}

/*二级所有目录高度*/

#main #leftMenu .head {
  display: flex;
  height: 60px;
  line-height: 60px;
  color: #fff;
  align-items: center;
  justify-content: space-evenly;
  background-color: #ffd294;
  margin: 0 -5px;
}

#main #leftMenu .headImg {
  width: 73px;
  height: 32px;
  margin-top:-10px;
  margin-right:3px;
}

#main #leftMenu .headTitle {
  cursor: pointer;
  color: #ffffff;
  font: 500 20px/28px Roboto, "PingFang HK", "PingFang SC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
  font-weight: bold;
}

#main #leftMenu .headIcons {
  cursor: pointer;
  font-size: 20px;
  margin-top:-4px;
}

#main #leftMenu .headIcons:hover{
  color: #c18b7f;
}

#main #leftMenu .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #eebd79;
  color: #fff;
}

#main #leftMenu .ant-menu-submenu-title{
  color: #fff;
}
#main #leftMenu .ant-menu-submenu-title:hover{
  color: #c18b7f;
}
#main #leftMenu .ant-menu-submenu-arrow{
  color: #fff;
  opacity:1;
}

#main #leftMenu .ant-menu-item{
  color: #fff;
}

#main #leftMenu .ant-menu-item:hover{
  color: #c18b7f;
}

#main #leftMenu .ant-menu-item-selected {
  background-color: #fff;
  border-radius: 2px;
  color: #eebd79;
}

.ant-menu-submenu-popup .ant-menu-sub .ant-menu-item .ant-menu-title-content{
  color: #fff;
}

.ant-menu-submenu-popup .ant-menu-sub .ant-menu-item .ant-menu-title-content:hover{
  color: #c18b7f;
}

#main #leftMenu .funcRow {
  display: flex;
  align-items: center;
}

#main #leftMenu .funcIcon {
  width: 20px;
}

#main #leftMenu .funcImg {
  width: 16px;
  height: 16px;
}

#main #leftMenu .fontWtIcon {
  padding-left: 10px;
}

#main #leftMenu .fontWt {
  padding-left: 20px;
}

#main #leftMenu .fontWt :hover{
  color: #52c41a!important;
}

#main #leftMenu .ant-menu-inline .ant-menu-item, #main #leftMenu .ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}

/*隐藏menu*/

.hideLeftMenu {
  width: 90px !important;
}

.showLeftMenu {
  width: 210px !important;
}

.showMenu {
  left: 0px !important;
}

/* ----------------- 中间左边菜单栏 end ----------------- */

/* ----------------- 中间右边内容 ----------------- */

#main .showRightWidth {
  width: calc(100% - 210px);
}

#main .hideRightWidth {
  width: calc(100% - 90px);
}

#main #rightMain .head {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 0px 5px 0px #1d1d1d;
  margin-bottom: 5px;
}

#main #rightMain .headCol {
  display: flex;
  align-items: center;
  margin-right: 25px;
  color: black;
  border: 1px;
  cursor:pointer;
}

#main #rightMain .headCol:hover{
 color: #eebd79;
}

.ant-tabs-tab:hover {
  color: #eebd79;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #eebd79;
  pointer-events: none;
}

.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #eebd79;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}

/* .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: black;
} */


#main #rightMain .headCols {
  display: flex;
  align-items: center;
  margin-right: 25px;
  color: rgb(204, 200, 200);
  width: 30px;
  height: 30px;
  border: 1px solid #d3cfcf;
  border-radius: 15px;
}
#main #rightMain .headCols img{
  width: 16px;
  margin-left: 5.5px;
  cursor:pointer;
}
#main #rightMain .headCol:hover {
  color: #eebd79;
}

.sysSerchInputTime{
  width: 180px;
  margin-left: 20px;
}

.sysSerchInputTimeMax{
  width: 190px;
  margin-left: 20px;
}


.sysTitleTime {
  font-size: 14px;
  color: #707070;
}

/*所有模块标题样式*/

.template {
  padding: 10px 15px;
  background-color: #f8f9fc;
  min-height: calc(100vh - 65px);
  overflow: hidden;
}

.breadcrumb{
  color: #707070;
}

.breadcrumbTitle {
  color: #333;
}

.templateMain{
  margin-top: 10px;
  background-color: #fff;
  padding: 15px;
}

.sysModelTitle {
  font-weight: 550;
  color: #000;
}

.sysRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-right: -47px;
}

.template .ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum", "tnum";
  display: inline-block;
  font-size: 0;
  margin-left: 10px !important;
}


.sysCol {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 47px;
}

.sysColMin {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sysCols {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 22px;
}

.sysTitle {
  font-size: 14px;
  color: #707070;
}

.sysTitles {
  font-size: 14px;
  color: #707070;
  width: 70px;
}

.sysTitlefour{
  font-size: 14px;
  color: #707070;
  width: 55px;
}

.greyColor{
  color: #707070;
  font-size: 13px;
}

.sysTitleTwo {
  font-size: 14px;
  color: #707070;
  width: 56px;
}

.sysTitleThree {
  font-size: 14px;
  color: #707070;
  width: 82px;
}

.sysTitleMax {
  font-size: 14px;
  color: #707070;
  width: 85px;
}

.sysParam {
  font-size: 14px;
  width: 170px;
  color: #707070;
}
.paddingLeft{
  color: #eebd79;
  text-decoration:underline;
  font-size: 14px;
  padding-left: 135px;
}
.hyperlink{
  color: #eebd79;
  text-decoration:underline;
}

.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #eebd79;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
  color: #eebd79;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #eebd79;
  border-color: #eebd79;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #eebd79;
}

.ant-radio-inner::after {
  background: #eebd79;
}

.auditRowTop {
  color:#7e7e7e;
}

.ant-select-arrow{
  color: rgba(0, 0, 0, 0.85);
}

.auditRowTop input {
  border: 0;
  background-color: #f7f8fa;
}

.auditRowTop input:focus {
  border: 0;
  background-color: #f7f8fa;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #eebd79;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #eebd79;
  border-radius: 2px;
  content: '';
}

.ant-btn-primary {
  color: #fff;
  border-color: #eebd79;
  background: #eebd79;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

a{
  color: #eebd79;
}

.ant-pagination-item-active a {
  color: #eebd79;
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #eebd79;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #eebd79;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
  color: #eebd79;
}

.ck-rounded-corners :is(.ck.ck-editor__main>.ck-editor__editable), .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  border-radius: var(--ck-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sysInputs {
  width: 1037px;
  margin-left: 20px;
}
.sysInput {
  width: 400px;
  margin-left: 20px;
}
.sysSerchInput{
  width: 300px;
  margin-left: 20px;
}

.sysInput input {
  border-radius: 3px !important;
  border-color: #dfdfe0 !important;
}

.sysInput input:focus {
  border: 0;
  background-color: #f7f8fa;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: #f7f8fa;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background-color: #f7f8fa;
  /* border: 0; */
}

.sysInput Select {
  border-radius: 3px;
  border-color: #dfdfe0;
}

.sysInput Select:focus {
  border-radius: 3px;
  border-color: #dfdfe0;
}

.ant-Select-status-error:not(.ant-Select-disabled):not(.ant-Select-borderless).ant-Select:hover {
  background-color: #f7f8fa;
  border: 0;
}

.sysSearchButton {
  width: 220px;
  border-left: 2px solid #f8f9fc;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: auto;
  justify-content: flex-end;
  margin-right: 42px;
}

.sysBtn {
  border: 0;
  background-color: #f2f3f5;
  color: #333;
}
.sysBtnTwo{
  border: 0;
  background-color: #f2f3f5;
  color: #333;
  margin-right: 5px;
}

.sysSearchButton .sysBtn, .sysSaveButton .sysBtn {
  margin: 0 5px;
}

.sysBtn:hover {
  background-color: #f2f3f5;
  color: #333;
}
.userRoleTitleRow{
  margin-top: 30px;
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 700;
}

.sysBtn:focus {
  border: 0;
  background-color: #f2f3f5;
  color: #333;
}

.btnBule{
  background-color: #eebd79;
  color: #fff;
}

.btnBule:hover {
  background-color: #c18b7f;
  color: #fff;
}

.btnBule:focus {
  border: 0;
  background-color: #eebd79;
  color: #fff;
}

.sysLineEight {
  height: 5px;
  background: #f8f9fc;
  margin: 1rem -15px;
}

.sysButtonRelative {
  margin-top: 10px;
}

.sysTable {
  margin-top: 20px;
}

.sysInputNumber {
  color: #333;
  width: 100%;
  border-radius: 3px !important;
  border-color: #dfdfe0 !important;
}

.sysSelect .ant-select-selector {
  border-radius: 3px !important;
  border-color: #dfdfe0 !important;
}

.sysInput textarea {
  border-radius: 3px !important;
  border-color: #dfdfe0 !important;
}

.sysInputs textarea {
  border-radius: 3px !important;
  border-color: #dfdfe0 !important;
}


.sysSaveButton {
  width: 100%;
  margin-top: 1rem;
  text-align: right;
}

.sysOperation {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 13px;
}

.sysUpdate {
  color: #eebd79;
  cursor:pointer;
}

.sysOperation {
  color: #f09f25d0;
  cursor:pointer;
}

.sysDelete {
  color: #ff4042;
  cursor:pointer;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  line-height: 1.1;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-family: inherit;
}

.h3, h3 {
  font-size: 24px;
}

.h4, h4 {
  font-size: 18px;
}

/*所有模块Tab样式*/

.sysTabs {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1rem;
  border: 2px solid #e2f0d9;
  padding: 15px;
  background-color: #fff;
}

.sysSubTable {
  border: 2px solid #e2f0d9;
  border-right: 0px;
  margin: -8px -17px -9px 86px !important;
}

/* 所有模块頁面栏位邊框樣式 */

.showTitleRow{
  font-size: 14px;
  font-weight: 700;
}

/*行样式：没有输入框，纯显示栏位用这个 */
.sysNoTitle{
  width: 130px;
  height: auto;
  min-height: 24px;
  word-wrap:break-word;
  word-break:break-all;
}
.sysNoInput{
  padding: 0 15px;
  width: 270px;
  height: auto;
  min-height: 24px;
  word-wrap:break-word;
  word-break:break-all;
}
.sysNoMaxInput{
  padding: 0 15px;
  width: 390px;
  height: auto;
  min-height: 24px;
  word-wrap:break-word;
  word-break:break-all;
}
.sysNoMaxTextArea{
  padding: 0 15px;
  width: 670px;
  height: auto;
  min-height: 24px;
  word-wrap:break-word;
  word-break:break-all;
}

.sysBodyRow {
  margin: 1rem 0;
}

.sysSpan {
  height: 30px;
  padding-top: 4px;
  display: block;
}

.sysSavePageWidth{
  max-width: 1280px;
}

.sysSearchRadio{
  line-height: 34px;
}

.errorInfoDiv {
  color: #f5222d;
}

.pickUpInput {
  font-size: 16px;
  font-weight: bold;
  color: #52c41a !important;
}

.sysSelect {
  width: 100%;
}

.systabls{
  margin-top: 20px;
}

.ant-switch-checked {
  background-color: #eebd79;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #eebd79;
  border-color: #eebd79;
}

.sysRoleInputNumber{
  width: 100%;
  border-radius: 3px !important;
  border-color: #dfdfe0 !important;
}

.sysClear {
  clear: both;
  display: flex;
}

.sysFloatLeft {
  float: left;
}

.sysFloatRight{
  float: right;
}

.sysRightWidth {
  width: 100%;
}

.previousImg{
  float: left;
  z-index: 999;
  position: absolute;
  top: 10px;
  right: 5px;
}

.previousIcon{
  width: 25px;
  height: 25px;
}

.productManageTableImg{
  width: 56px;
  height: 56px;
}
/* 必填显示*样式 */

/* 上传组件 视频缩略图样式 start */
.videoUpload .ant-upload-list-picture .ant-upload-list-item-thumbnail{
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.videoUpload .ant-upload-list-picture .ant-upload-list-item-thumbnail img{
  width: unset;
  height: unset;
}
/* 上传组件 视频缩略图样式 end */

/* a {
  color: #666;
} */

.mustFill:after {
  color: #f00;
  content: "*";
  /* position: absolute; */
  margin-left: 5px;
}

/* 按钮排版样式 */

.operationButton {
  margin-left: 5px;
  margin-top: 5px;
}

.ant-form-item-has-error .ant-input {
  border-color: #ff4d4f;
}

.ant-select-selector {
  border-color: #c1c1c1 !important;
}

.ant-form-item-has-error .ant-select-selector {
  border-color: #ff4d4f !important;
}

.ant-picker {
  border-radius: 3px !important;
  border-color: #dfdfe0 !important;
  width: 100%;
}

.ant-form-item-has-error .ant-picker {
  border-color: #ff4d4f !important;
}

.ant-input[disabled] {
  color: black !important;
  background-color:transparent !important;
  border: none;
}

.sysSelect .ant-select-selector[disabled] {
  border: 0 !important;
  background-color:transparent !important;
}

.ant-select-disabled .anticon > svg {
  vertical-align: top;
  display: none!important;
}

.ant-picker-input>input[disabled] {
  color: #333 !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent !important;
  color: #333;
  border: 0 !important;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  display: none;
}

.ant-modal-title{
  font-size: 20px;
}

/* Loading...样式 */

.ant-spin-lg .ant-spin-dot {
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
}

/* andt form表单校验样式修改 */

.ant-form-explain {
  height: auto;
  margin-top: -2px !important;
}

.ant-form-item {
  height: auto;
  margin: 0px !important;
  padding-bottom: 0px !important;
  flex: 1;
}

.ant-form-item-control {
  line-height: 32.9999px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 90px !important;
  height: 90px !important;
}

.ant-upload.ant-upload-select-picture-card {
  width: 90px !important;
  height: 90px !important;
}
.box{
  height: 40px;
  font-weight: 550;
  font-size: 15px;
}

/* antd时间插件样式 */

.ant-calendar-picker {
  width: 100%;
}

.ant-calendar-picker-input {
  height: 34px !important;
}

/**antd notification提示框 插件样式 */
.ant-notification-notice{
  padding: 16px 8px!important;
}

/**所有inputNumber框的 右邊上下箭頭 隱藏*/
.ant-form-item .ant-input-number-handler-wrap {
  display: none;
}

/**ckeditor组件高度*/
.ck-content{
  min-height: 200px;
}

/* input::placeholder {
  color: rgb(207, 199, 199)!important;
}

textarea::placeholder{
  color: rgb(207, 204, 204)!important;
} */

/* ----------------- 中间右边内容 end ----------------- */

/* ------------------------------------ 中间内容区域 end ------------------------------------ */













/* ------------------------------------ 不同设备兼容样式 ------------------------------------ */

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.tableTop {
  margin-top: 5px;
}
.tableZero {
  margin-top: 15px;
}
/** PC**/

@media only screen and (min-width: 1366px) {
  /* menu缩进排版样式 */
  .container {
    margin-left: 0px;
    width: 100%;
  }
  .hideMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 120px);
  }
  .showMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100%);
  }
  /* 登录界面样式 */
  .loginFormRoot {
    margin: 0 auto;
    margin-top: 7%;
  }
  .loginImg {
    width: 100%;
    text-align: center;
  }
  .loginImg img {
    width: 693px;
    height: 126px;
  }
  .tableTop {
    margin-top: -32px;
  }
  .deliveryDateLine {
    width: 47% !important;
    float: left;
  }
  .toStrLine {
    width: 6% !important;
    float: left;
    line-height: 34px;
    text-align: center;
  }
  .bookStop .routeWidth{
    width: 75px;
  }
  .bookStop .routeAddressWidth{
    width: 137px;
  }
  .bookStop .routeEffectDateWidth{
    width: 160px;
  }
  .bookStop .arriveTimeWidth{
    width: 110px;
  }
  .bookStop .bookRoute5{
    width: 111px;
  }
  .bookStop .bookRoute6{
    width: 109px;
  }
  .bookStop .routeRoundTripFeeWidth{
    width: 111px;
  }
  .bookStop .routeRoundTripTimeWidth{
    width: 87px;
  }
  .bookStop .routeRemarkWidth{
    width: 23px; 
  }
  
  .bookRoute .bookRouteWidth{
    width: 75px;
  }
  .bookRoute .bookRouteAddressWidth{
    width: 137px;
  }
  .bookRoute .bookRouteEfftWidth{
    width: 160px;
  }
  .bookRoute .bookRouteArriveTimeWidth{
    width: 110px;
  }
  .bookRoute .bookRouteOutTripFeeWidth{
    width: 111px;
  }
  .bookRoute .bookRouteEtaWidth{
    width: 79px;
  }
  .bookRoute .bookRouteRoundTripfeeWidth{
    width: 111px;
  }
  .bookRoute .bookRouteRoundTripTimeWidth{
    width: 87px;
  }
  .bookRoute .bookRouteRemarkWidth{
    width: 23px;
  }
  .bookRoute .sysClearRouteList{
    clear: both;
    display: flex;
  }
  .bookRoute .bookRoute1{
    width: 75px;
  }
  .bookRoute .bookRoute2{
    width: 137px;
  }
  .bookRoute .bookRoute3{
    width: 160px;
  }
  .bookRoute .bookRoute4{
    width: 110px;
  }
  .bookRoute .bookRoute5{
    width: 111px;
  }
  .bookRoute .bookRoute6{
    width: 109px;
  }
  .bookRoute .bookRoute7{
    width: 87px;
  }
}

/** iPad Pro**/

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  /* menu缩进排版样式 */
  .container {
    margin-left: 0px;
    /* width: 100%; */
    width: calc(100%);
  }
  .hideMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 120px);
  }
  .showMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100%);
  }
  /* 登录界面样式 */
  .loginFormRoot {
    margin: 0 auto;
    margin-top: 20%;
  }
  .loginImg {
    width: 100%;
    text-align: center;
  }
  .loginImg img {
    width: 693px;
    height: 126px;
  }
  .deliveryDateLine {
    width: 100% !important;
    float: left;
  }
  .toStrLine {
    width: 100% !important;
    float: left;
    /* line-height: 34px; */
    text-align: center;
  }
}

/** iPad **/

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* menu缩进排版样式 */
  .container {
    margin-left: 0px;
    /* width: 100%; */
    width: calc(100%);
  }
  .hideMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 120px);
  }
  .showMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100%);
  }
  /* 登录界面样式 */
  .loginFormRoot {
    margin: 0 auto;
    margin-top: 15%;
  }
  .loginImg {
    width: 100%;
    text-align: center;
  }
  .loginImg img {
    width: 346px;
    height: 63px;
  }
  .deliveryDateLine {
    width: 100% !important;
    float: left;
  }
  .toStrLine {
    width: 100% !important;
    float: left;
    /* line-height: 34px; */
    text-align: center;
  }
}

/** iPhone **/

@media only screen and (min-width: 321px) and (max-width: 767px) {
  /* menu缩进排版样式 */
  .container {
    margin-left: 0px;
    /* width: 100%; */
    /* width:calc(100%); */
    width: calc(100% + 200px);
  }
  .hideMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 200px);
  }
  .showMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 200px);
  }
  /* 登录界面样式 */
  .loginFormRoot {
    margin: 0 auto;
    margin-top: 20%;
  }
  .loginImg {
    width: 100%;
    text-align: center;
  }
  .loginImg img {
    width: 346px;
    height: 63px;
  }
  .deliveryDateLine {
    width: 100% !important;
    float: left;
  }
  .toStrLine {
    width: 100% !important;
    float: left;
    /* line-height: 34px; */
    text-align: center;
  }
  .sysClear {
    flex-direction: column;
  }
  .sysRightWidth {
    margin-top: 50px;
    margin-left: 0px;
    width: 100%;
  }
  .bookStop .ant-timeline-item{
    padding-bottom: 30px!important;
  }
  .bookStop .clentCardDetailCol{
    margin-right: 0px!important;
  }
  .bookStop .routeWidth{
    width: calc(50% - 10px);
  }
  .bookStop .routeAddressWidth{
    width: calc(50% - 10px);
  }
  .bookStop .routeEffectDateWidth{
    width: calc(50% - 10px);
  }
  .bookStop .arriveTimeWidth{
    width: calc(50% - 10px);
  }
  .bookStop .bookRoute5{
    width: calc(50% - 10px);
  }
  .bookStop .bookRoute6{
    width: calc(50% - 10px);
  }
  .bookStop .routeRoundTripFeeWidth{
    width: calc(50% - 10px);
  }
  .bookStop .routeRoundTripTimeWidth{
    width: calc(50% - 10px);
  }
  .bookStop .routeRemarkWidth{
    width: calc(50% - 10px);
  }

  .bookRoute .bookRouteWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteAddressWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteEfftWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteArriveTimeWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteOutTripFeeWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteEtaWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteRoundTripfeeWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteRoundTripTimeWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteRemarkWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .btnWidth{
    width: 50%;
  }
  .bookRoute .sysClearRouteList{
    clear: both;
  }
  .bookRoute .bookRoute1{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute2{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute3{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute4{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute5{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute6{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute7{
    width: calc(50% - 10px);
  }

  .bookRouteTopImg{
    width: 365px;
    height: 120px;
  }
  
}

@media only screen and (max-width: 320px) {
  /* menu缩进排版样式 */
  .container {
    margin-left: 0px;
    /* width: 100%; */
    /* width:calc(100%); */
    width: calc(100% + 200px);
  }
  .hideMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 200px);
  }
  .showMenu_container {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% + 200px);
  }
  /* 登录界面样式 */
  .loginFormRoot {
    margin: 0 auto;
    margin-top: 20%;
  }
  .loginImg {
    width: 100%;
    text-align: center;
  }
  .loginImg img {
    width: 346px;
    height: 63px;
  }
  .deliveryDateLine {
    width: 100% !important;
    float: left;
  }
  .toStrLine {
    width: 100% !important;
    float: left;
    /* line-height: 34px; */
    text-align: center;
  }
  .sysClear {
    flex-direction: column;
  }
  .sysRightWidth {
    margin-top: 50px;
    margin-left: 0px;
    width: 100%;
  }
  .bookStop .ant-timeline-item{
    padding-bottom: 30px!important;
  }
  .bookStop .clentCardDetailCol{
    margin-right: 0px!important;
  }
  .bookStop .routeWidth{
    width: calc(50% - 10px);
  }
  .bookStop .routeAddressWidth{
    width: calc(50% - 10px);
  }
  .bookStop .routeEffectDateWidth{
    width: calc(50% - 10px);
  }
  .bookStop .arriveTimeWidth{
    width: calc(50% - 10px);
  }
  .bookStop .bookRoute5{
    width: calc(50% - 10px);
  }
  .bookStop .bookRoute6{
    width: calc(50% - 10px);
  }
  .bookStop .routeRoundTripFeeWidth{
    width: calc(50% - 10px);
  }
  .bookStop .routeRoundTripTimeWidth{
    width: calc(50% - 10px);
  }
  .bookStop .routeRemarkWidth{
    width: calc(50% - 10px);
  }

  .bookRoute .bookRouteWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteAddressWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteEfftWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteArriveTimeWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteOutTripFeeWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteEtaWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteRoundTripfeeWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteRoundTripTimeWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRouteRemarkWidth{
    width: calc(50% - 10px);
  }
  .bookRoute .btnWidth{
    width: 50%;
  }
  .bookRoute .sysClearRouteList{
    clear: both;
  }
  .bookRoute .bookRoute1{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute2{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute3{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute4{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute5{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute6{
    width: calc(50% - 10px);
  }
  .bookRoute .bookRoute7{
    width: calc(50% - 10px);
  }
  
  .bookRouteTopImg{
    width: 365px;
    height: 120px;
  }

  .clentBtn{
    padding: 4px 5px;
  }
}

/* ------------------------------------ 不同设备兼容样式 end------------------------------------ */

.ant-popover-content {
  max-width: 1000px;
  word-break: break-all;
  word-wrap: break-word;
}

.blueBox{
  position: relative;
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid #ccd6ff;
  padding: 15px 20px 10px 10px;
  margin-bottom: 25px;
  margin-top: 30px;
}
.blueBoxTitle{
  margin-top: 15px;
  position: absolute;
  top: -25px;
  left: 30px;
  background-color: #fff;
  width: 10%;
  text-align: center;
}
.blueBoxDescriptions{
  margin-top: 5px;
}
.blueBoxRow{
  margin-left: unset !important; 
  margin-right: unset !important;
}

.blueBoxRowText{
  display: inline-flex;
  align-items: center;
}

.showTitle{
  font-size: 18px;
}

.titleSpan{
  color: #7e7e7e;
}

.ant-input-number-input{
  border: 1px solid #d9d9d9;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
  width: 80%;
  display: flex;
  justify-content: space-between;
}