.popupFrameCenter{
    text-align:center;
    font-size: 16px;
}

.popupFrameAlign{
    margin-top:14%;
    border-radius: 4px !important;
}

.popupFrameAlign .ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    /* margin: 0 auto; */
    margin: 0 0 0 8px;
    border-radius: 0 0 2px 2px;
    text-align: center;
}

.popupFrameCenter .btn-primary{
    color: #fff;
    background-color: #1e3e8f;
    border-color: #2e6da4;
}