.errorBorder{
    border: 1px solid #f5222d!important;
}
.errorBorder:hover{
    border: 1px solid #f5222d!important;
}
.errorBorder:focus{
    border: 1px solid #f5222d!important;
}

.errorMsg{
  color: #f5222d;
  font-size: 13px;
  clear: both;
  /* height: 18px; */
}

.sysUserSpan{
    height: 32px;
    padding-top: 6px;
    display: block;
}
.sysUserOrderButton{
    margin-top: 5px;
    text-align: right;
    margin-bottom: 1rem;
}
.sysUserButtonRight {
    margin-left: 5px;
}
.sysUserLineThree{/* 线条 */
    position: relative;
    width: 100%;
    height: 2px;
    top: 13px;
    background: #e2f0d9;
}
.sysUserBodyRow {
    margin: 1rem 1rem 1rem 0;
}
.titleRowOne {
    margin-top: 30px;
    margin-bottom: 20px;
}
.titleRowTwo {
    margin-top: 50px;
    margin-bottom: 20px;
}
.parts-numberInput{
    width: 100%;
}

.passwordManageButtonRight {
  margin-left: 5px;
}

