.titleRowTop {
    margin-top: 10px;
    margin-bottom: 0px;
}
.titleRow {
    margin-top: 30px;
    margin-bottom: 0px;
}
.textLeft{
    text-align: left!important;
}
.parts-numberInput{
    width: 100%;
}
.minInput{
    display: inline-block !important;
    width: 310px !important;
}
.sysInputFlex{
    display: flex;
}
.sysMinInput{
    width: 80px !important;
    display: inline-block !important;
}