.proBrandLogo{
    width: 56px;
    height: 56px;
}
.proBrandDesc{
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.plusOutBox{
    position: relative;
}
.plusOutDesc{
    font-size: 12px;
    color: #888;
    position: absolute;
    top: 3px;
    left: 90px;
    width: 130px;
}