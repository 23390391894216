.sysUserFavoriteTabs{
    width: 50%;
    min-width: 540px;
    margin-top: 0;
    margin-bottom: 1rem;
    border: 2px solid #e2f0d9;
    padding: 15px;
}



.sysUserFavoriteShuttle {
    width: 830px;
}

.sysUserFavoriteFavourite{
    width: 32%;
    height: 420px; 
    float: left;
}
.sysUserFavoriteSelect{
    width: 32%;
    height: 420px; 
    float: left;
    margin-left: 2%;
}
.sysUserFavoriteTitle{
    font-weight:bold;
    margin:10px 0 0 0px ;
}
.sysUserFavoriteLeft{
    margin-top: 16px ;
    border: 1px solid #d9d9d9;
    height: 420px;
    width: 100%;
    float: left;
    overflow-y:auto;
    overflow-x:auto;
}
.sysUserFavoriteLeft .ant-tree{
    width: 200px;
}
.sysUserFavoriteTransmit{
    text-align: center;
    height: 300px;
    line-height: 300px;
    float: left;
    margin-left: 20px;
    margin-right: 20px;
}
.sysUserFavoriteTransmitRight{
    margin-top: 85px;
}
.sysUserFavoriteTransmitRight .anticon svg{
    width: 35px!important;
    height: 45px!important;
    background-color: #eebd79;
    color: #ffffff;
    border-radius: 3px;
}
.sysUserFavoriteRight{
    border: 1px solid #d9d9d9;
    width: 100%;
    margin-top: 16px ;
    min-height: 300px;
    height: 420px;
    float: left;
    /* overflow-y:auto;
    overflow-x:auto; */
}
.sysUserFavoriteaaa{
    overflow-y:auto;
    overflow-x:auto;
    height: 417px;
}
.sysUserFavoriteFunc{
    margin: 2%;
    width: 95%;
    padding: 1%;
    border: 1px solid #d9d9d9;
    height: 30px;
    line-height: 24px;
}
.sysUserFavoriteFuncName{
    float: left;
    width: 60%;
    margin-left: 5px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
}
.sysUserFavoriteClose{
    position: relative;
    float: right;
    margin-right: 2px;
    width: 8%;
}
.sysUserFavoriteUp{
    position: relative;
    float: right;
    margin-right: 2px;
    width: 8%;
}
.sysUserFavoriteDown{
    position: relative;
    float: right;
    width: 8%;
}
.sysUserFavoriteButton {
    clear: both;
    padding-top: 6rem;
    padding-right: 25px;
    width: 100%;
    margin-bottom: 1rem;
    text-align: right;
}
.sysUserFavoriteButtonRight {
    margin-left: 5px;
}
.sysUserFavorite .nav-tabs{
    border-bottom: 0;
}

.sysUserFavoriteUp .up{
    display:none;/*默认隐藏*/
}

.sysUserFavoriteUp:hover .up{
    display:initial;/*当鼠标hover时展示*/
    width: 40px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    bottom: -15px;
    left: -12px;
    border: 1px solid #d9d9d9;
    border-radius:5px;
    border-width: 1px 2px 2px 1px;
    background-color: #d9d9d9;
    font-size: 7pt;
    text-align: center;
}
.sysUserFavoriteDown .down{
    display:none;/*默认隐藏*/
}

.sysUserFavoriteDown:hover .down{
    display:initial;/*当鼠标hover时展示*/
    width: 40px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    bottom: -15px;
    left: -12px;
    border: 1px solid #d9d9d9;
    border-radius:5px;
    border-width: 1px 2px 2px 1px;
    background-color: #d9d9d9;
    font-size: 7pt;
    text-align: center;
}


