.newLoginFormRoot {
    width: 100%;
    margin: 0 auto;
}
.newLoginFormRoot .newloginBackground {
    width:100% ;
    height: 100vh;
    background: url("../../public/img/loginBack.jpg")  ;
    background-size: cover;
}

.newLoginFormRoot .topDiv{
    position: absolute;
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 20px;
    line-height: 28px;
}
.newLoginFormRoot .topDiv .headPortrait img{
    width: 100px;
}

.newLoginFormRoot .topDiv .title{
    font-size: 26px;
    font-weight: bold;
}

.newLoginFormRoot .bodyDiv{
    position: absolute;
    left: calc(50% - 500px);
    top: calc(50% - 200px);
    display: flex;
    background-color: #ffffff;
    border-radius: 15px;
    width: 1000px;
    height: 400px;
    box-shadow: 0px 0px 20px 0px rgb(59, 59, 59);
}

.newLoginFormRoot .bodyDiv .leftBox img{
    height: 400px;
    width: 696px;
    border-radius: 15px;
}

.newLoginFormRoot .bodyDiv .rightBox{
    height: 400px;
    width: 304px;
    padding: 25px;
}

.newLoginFormRoot .bodyDiv .rightBox .titleBox{   
    height: 110px;
}

.newLoginFormRoot .bodyDiv .rightBox .titleBox .title1{
    font-weight: bold;
    text-align: center;
    margin: 15px 0;
    font-size: 26px;
}
.newLoginFormRoot .bodyDiv .rightBox .title{
    font-weight: bold;
    font-size: 18px;
}

.newLoginFormRoot .bodyDiv .rightBox .titleBox .jianbiantiao{
    width: 80px;
    height: 4px;
    border-radius: 2.5px;
    background-image: linear-gradient(to right, #eebd79 , rgb(255, 255, 255));
}


.newLoginFormRoot .bodyDiv .rightBox .textBox .userBox{
    display: flex;
	margin-bottom: 15px;
    border-bottom: 1px solid rgb(160, 158, 158);
}

.newLoginFormRoot .bodyDiv .rightBox .textBox .pwdBox{
    display: flex;
	margin-bottom: 15px;
    border-bottom: 1px solid rgb(160, 158, 158);
}

.newLoginFormRoot .bodyDiv .rightBox .textBox .userImg img{
    width: 20px;
    height: 20px;
    margin: 7px;
}

.newLoginFormRoot .bodyDiv .rightBox .textBox .loginFormButton {
    width: 100%;
    height: 35px;
    left: 0%;
    margin-top: 10px;
    font-size: 14px;
    background: #eebd79;;
    border-color: #eebd79;;
}

.newLoginFormRoot .bodyDiv .rightBox .textBox input{
    width: 100%;
	outline: none;
	border: none;
    background: none;
	font-size: 16px;
    font-weight: 500;
}

.newLoginFormRoot .bodyDiv .rightBox .textBox input:focus{
    border: none;
    outline: none;
}
.newLoginFormRoot .bodyDiv .rightBox .textBox .ant-input:focus{
    border: none;
    outline: none;
    box-shadow:none;

}

.bot {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    color: #ffffff !important;
  }
.goBeiAn{
    cursor: pointer;  /*鼠标箭头变手指*/
}
