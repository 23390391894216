
.changUserInfoSpan{
    height: 32px;
    padding-top: 6px;
    display: block;
}
.changUserInfoOrderButton{
    margin-top: 5px;
    text-align: right;
    margin-bottom: 1rem;
}
.changUserInfoButtonRight {
    margin-left: 5px;
}
.parts-numberInput{
    width: 100%;
}
.titleRowTop {
    margin-top: 25px;
    margin-bottom: 15px;
}
.titleRow {
    margin-top: 30px;
    margin-bottom: 20px;
}

