.proEstateImg{
    width: 56px;
    height: 56px;
}
.proEstateTreeDiv{
    display: flex;
    justify-content: space-between;
}
.proEstate .treeDiv{
    width: calc(100% - 93px);
    min-height: 30px;
    max-height: 200px;
    padding: 4px 6px;
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}
.proEstate .treeDiv .lawArticleItem{
    width: fit-content;
    margin: 8px 8px 0 2px;
    background-color: #e5e5e5;
    cursor: pointer;
    display: flex;
    word-break: break-all;
}
.proEstate .treeDiv .closeImg{
    position: relative;
    top: -6px;
    right: -6px;
    color: #787878;
}
.proEstate #apartmentMap{
    width: 100%;
    height: 400px;
}
.estateModal{
    margin-top:14%;
    border-radius: 4px !important;
}

.estateModal .ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    /* margin: 0 auto; */
    margin: 0 0 0 8px;
    border-radius: 0 0 2px 2px;
    text-align: center;
}
.colorRedModal{
    color: red;
}